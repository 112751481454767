import gql from 'graphql-tag'


export const DETAIL_USER = (templateType) => gql`query DETAIL_USER ($profileType: String!) {
  profileUser: detailProfile${templateType} (profileType: $profileType) {
    id name tag email isPassword
  }
}`

export const UPDATE_PASSWORD = (templateType) => gql`mutation UPDATE_PASSWORD ($profileType: String!, $oldPassword: String!, $newPassword: String!) {
  updatePassword: updateProfile${templateType}Password (profileType: $profileType, oldPassword: $oldPassword, newPassword: $newPassword)
}`
