<template>
  <div>
    <div class="mb-3">
      เมื่อยืนยันจะทำการลบข้อมูลที่จัดเก็บที่อยู่บนอุปกรณ์นี้เท่านั้น<br />
      - ไม่ส่งผลกระทบต่อฐานข้อมูลของบริษัท<br />
      - ลบข้อมูลการค้นหาต่างๆ การจดจำหน้าต่าง
    </div>
    <button
      class="btn btn-danger"
      @click="clearCache">
      ยืนยัน
    </button>
    <button
      class="btn btn-primary ml-2"
      @click="emitMethod(null)">
      ยกเลิก
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    emitMethod (method) {
      this.$emit('method', method)
    },
    clearCache () {
      const username = localStorage.getItem(process.env.VUE_APP_USER_NAME)
      if (username) {
        localStorage.removeItem(username)
      }
      this.emitMethod(null)
      this.$toasted.global.success("ลบข้อมูลสำเร็จ")
    }
  }
}
</script>
