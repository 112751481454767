<template>
  <div>
    <div class="form-row">
      <sgv-input-password
        label="รหัสผ่านเก่า"
        class="col-12"
        v-model="formData.oldPassword"
        :validations="[
          {text: 'โปรดระบุข้อมูล', value: $v.formData.oldPassword.$dirty && !$v.formData.oldPassword.required}
        ]">
      </sgv-input-password>

      <sgv-input-password
        label="รหัสผ่านมใหม่"
        class="col-12"
        v-model="formData.newPassword"
        :validations="[
          {text: 'โปรดระบุข้อมูล', value: $v.formData.newPassword.$dirty && !$v.formData.newPassword.required}
        ]">
      </sgv-input-password>

      <sgv-input-password
        label="ยืนยันรหัสผ่านใหม่"
        class="col-12"
        v-model="formData.confirmPassword"
        :validations="[
          {text: 'โปรดระบุข้อมูล', value: $v.formData.confirmPassword.$dirty && !$v.formData.confirmPassword.required},
          {text: 'รหัสผ่านไม่ตรงกัน', value: $v.formData.confirmPassword.$dirty && !$v.formData.confirmPassword.sameAsPassword}
        ]">
      </sgv-input-password>
    </div>

    <button
      class="btn btn-danger"
      @click="changePassword">
      ยืนยัน
    </button>

    <button
      class="btn btn-primary ml-2"
      @click="emitMethod(null)">
      ยกเลิก
    </button>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import { UPDATE_PASSWORD } from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    profileType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  validations: {
    formData: {
      oldPassword: { required },
      newPassword: { required },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(function() {
          return this.formData.newPassword
        })
      },
    }
  },
  methods: {
    emitMethod (method) {
      this.$emit('method', method)
    },
    serializeInput (v) {
      return  {
        oldPassword: v.oldPassword,
        newPassword: v.newPassword,
      }
    },
    changePassword () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_PASSWORD(this.templateType),
        variables: {
          ...input,
          profileType: this.profileType
        }
      })
      .then(() => {
        this.emitMethod(null)
        this.$toasted.global.success("เปลี่ยนรหัสผู้ใช้สำเร็จ")
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>
