<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-12"
        label="ชื่อ"
        v-model="formData.name">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="รหัสบัตร"
        v-model="formData.tag">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="E-mail"
        v-model="formData.email">
      </sgv-input-text>
    </div>

    <button
      type="button"
      class="btn btn-warning mb-3"
      @click="setMethod('clearCache')">
      ลบข้อมูลบนอุปกรณ์
    </button>

    <button
      v-if="formData.isPassword"
      type="button"
      class="btn btn-primary mb-3 ml-2"
      @click="setMethod('changePassword')">
      เปลี่ยนรหัสผ่าน
    </button>

    <UserClearCache
      v-if="method === 'clearCache'"
      @method="method = null">
    </UserClearCache>

    <UserChangePassword
      v-if="method === 'changePassword' && formData.isPassword"
      @method="method = null"
      :templateType="templateType"
      :profileType="profileType">
    </UserChangePassword>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { DETAIL_USER } from './graph'
import UserClearCache from './UserClearCache.vue'
import UserChangePassword from './UserChangePassword.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.title
    }
  },
  props: {
    profileType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        name: '',
        tag: '',
        email: '',
        isPassword: false
      },
      method: null
    }
  },
  apollo: {
    profileUser: {
      query () {
        return DETAIL_USER(this.templateType)
      },
      variables () {
        return {profileType: this.profileType}
      },
      fetchPolicy: 'network-only',
      result (res) {
        this.setFormData(res.data.profileUser)
      }
    }
  },
  methods: {
    setMethod (method) {
      if (this.method === method) {
        this.method = null
      } else {
        this.method = method
      }
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    UserClearCache,
    UserChangePassword
  }
}
</script>
