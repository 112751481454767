var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-password',{staticClass:"col-12",attrs:{"label":"รหัสผ่านเก่า","validations":[
        {text: 'โปรดระบุข้อมูล', value: _vm.$v.formData.oldPassword.$dirty && !_vm.$v.formData.oldPassword.required}
      ]},model:{value:(_vm.formData.oldPassword),callback:function ($$v) {_vm.$set(_vm.formData, "oldPassword", $$v)},expression:"formData.oldPassword"}}),_c('sgv-input-password',{staticClass:"col-12",attrs:{"label":"รหัสผ่านมใหม่","validations":[
        {text: 'โปรดระบุข้อมูล', value: _vm.$v.formData.newPassword.$dirty && !_vm.$v.formData.newPassword.required}
      ]},model:{value:(_vm.formData.newPassword),callback:function ($$v) {_vm.$set(_vm.formData, "newPassword", $$v)},expression:"formData.newPassword"}}),_c('sgv-input-password',{staticClass:"col-12",attrs:{"label":"ยืนยันรหัสผ่านใหม่","validations":[
        {text: 'โปรดระบุข้อมูล', value: _vm.$v.formData.confirmPassword.$dirty && !_vm.$v.formData.confirmPassword.required},
        {text: 'รหัสผ่านไม่ตรงกัน', value: _vm.$v.formData.confirmPassword.$dirty && !_vm.$v.formData.confirmPassword.sameAsPassword}
      ]},model:{value:(_vm.formData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.formData, "confirmPassword", $$v)},expression:"formData.confirmPassword"}})],1),_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.changePassword}},[_vm._v(" ยืนยัน ")]),_c('button',{staticClass:"btn btn-primary ml-2",on:{"click":function($event){return _vm.emitMethod(null)}}},[_vm._v(" ยกเลิก ")])])}
var staticRenderFns = []

export { render, staticRenderFns }